import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { ISourceTextProps, SourceText } from '../atoms/SourceText';
import { PlaySaveActions, IPlaySaveActionsProps } from '../molecules/PlaySaveActions';
import { ITtsSettingsProps, TtsSettings } from '../molecules/TtsSettings';
import { IVelocityPitchSliderProps, VelocityPitchSlider } from '../molecules/VelocityPitchSlider';

import styles from './TextToSpeech.module.scss';

export interface ITextToSpeechProps {
	source: ISourceTextProps;
	settings: ITtsSettingsProps;
	slider: IVelocityPitchSliderProps;
	actions: IPlaySaveActionsProps;
}

export const TextToSpeech = (props: ITextToSpeechProps): JSX.Element => {
	return (
		<div className={[styles.ttsRow, styles.ttsFont].join(' ')}>
			<div className={styles.ttsSource}>
				<SourceText {...props.source} />
			</div>
			<div className={styles.ttsSettings}>
				<TtsSettings {...props.settings} />
				<VelocityPitchSlider {...props.slider} />
				<PlaySaveActions {...props.actions} />
			</div>
			{!props.actions.testing ? (
				<></>
			) : (
				<ReactAudioPlayer src={props.actions.audioUrl} autoPlay onEnded={props.actions.onStop} />
			)}
		</div>
	);
};
