interface IConfiguration {
	tenantId: string;
	clientId: string;
	api: string;
	functionKey: string;
	scope: string;
}

export class Configuration {
	private currentConfig: IConfiguration;

	constructor() {
		this.currentConfig = {
			tenantId: process.env.REACT_APP_TENANTID,
			clientId: process.env.REACT_APP_CLIENTID,
			api: process.env.REACT_APP_API,
			functionKey: process.env.REACT_APP_FUNCTIONKEY,
			scope: process.env.REACT_APP_SCOPE,
		} as IConfiguration;
	}

	get tenantId(): string {
		return this.currentConfig.tenantId;
	}

	get clientId(): string {
		return this.currentConfig.clientId;
	}

	get api(): string {
		return this.currentConfig.api;
	}

	get functionKey(): string {
		return this.currentConfig.functionKey;
	}

	get scope(): string {
		return this.currentConfig.scope;
	}
}
