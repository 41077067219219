import { useAccount, useMsal } from '@azure/msal-react';
import React, { useState, useEffect, useMemo } from 'react';

import { Configuration } from '../config/Configuration';
import { ITtsSettings } from '../models/ITtsSettings';
import { SettingsService } from '../services/SettingsService';

export const useSettings = (): [ITtsSettings, React.Dispatch<React.SetStateAction<ITtsSettings>>] => {
	const config = useMemo(() => new Configuration(), []);
	const [settings, setSettings] = useState<ITtsSettings>({ enabledSynth: true, enabledAdb: true });
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0]);

	const getAccessToken = async () => {
		if (!account) return Promise.reject('no authorized user');

		const response = await instance.acquireTokenSilent({
			scopes: [config.scope],
			account,
		});

		if (!response) return Promise.reject('no access token retrieved');

		return response.accessToken;
	};

	useEffect(() => {
		const load = async (): Promise<ITtsSettings> => {
			const accessToken = await getAccessToken();
			const settings = await SettingsService.Load(accessToken);

			return settings;
		};
		load()
			.then((settings) => {
				setSettings(settings);
			})
			.catch((e) => console.error(e));
	}, [account]);

	useEffect(() => {
		const save = async (settings: ITtsSettings) => {
			const accessToken = await getAccessToken();
			await SettingsService.Upsert(settings, accessToken);
		};
		save(settings);
	}, [settings]);

	return [settings, setSettings];
};
