import React, { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { Persona, PersonaSize, PersonaPresence, IPersonaSharedProps } from 'office-ui-fabric-react';

import styles from './UserInfo.module.scss';
import { AccountService } from '../../services/AccountService';

export const UserInfo = (): JSX.Element => {
	const [picture, setPicture] = useState<string>();
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0]);

	const setAccountImage = async () => {
		if (account) {
			const response = await instance.acquireTokenSilent({
				scopes: ['User.Read'],
				account: account,
			});

			if (response) {
				const image = await AccountService.getAccountPicture(response.accessToken);
				setPicture(image);
			}
		}
	};

	useEffect(() => {
		setAccountImage();
	}, [account, instance]);

	const examplePersona: IPersonaSharedProps = {
		imageUrl: picture,
		text: account ? account.name : '',
		secondaryText: account ? account.username : '',
		showSecondaryText: true,
	};

	return account ? (
		<Persona
			{...examplePersona}
			className={styles.account}
			size={PersonaSize.size32}
			presence={PersonaPresence.none}
			imageAlt='current user account'
		/>
	) : inProgress === 'login' ? (
		<>Loging in</>
	) : (
		<>nothing</>
	);
};
