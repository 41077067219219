import React from 'react';
import { ILogItem } from '../../models/LogItem';
import { LogList } from '../molecules/LogList';

import styles from './Monitoring.module.scss';

export interface IMonitoringProps {
	items: ILogItem[];
}

export const Monitoring = (props: IMonitoringProps): JSX.Element => {
	return (
		<div>
			<div className={styles.headline}>Monitoring</div>
			<div>
				<LogList items={props.items} />
			</div>
		</div>
	);
};
