import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SettingsPanel } from './SettingsPanel';

export interface ITtsCommandBar {
	speechStudioUrl: string;
}

export const TtsCommandBar = (props: ITtsCommandBar): JSX.Element => {
	const history = useHistory();
	const [showSettings, setShowSettings] = useState(false);

	const cItems: ICommandBarItemProps[] = [
		{
			key: 'ttsCapture',
			text: 'Dateneingabe',
			iconProps: { iconName: 'Microphone' },
			onClick: () => history.push('/'),
		},
		{
			key: 'ttsMonitor',
			text: 'Monitoring',
			iconProps: { iconName: 'Diagnostic' },
			onClick: () => history.push('/monitoring'),
		},
		{
			key: 'ttsSpeechStudio',
			text: 'Speech Studio',
			iconProps: { iconName: 'Message' },
			onClick: () => window.open(props.speechStudioUrl, '_blank'),
		},
	];

	const farItems: ICommandBarItemProps[] = [
		{
			key: 'ttsSettings',
			text: 'Einstellungen',
			iconProps: { iconName: 'Settings' },
			onClick: () => setShowSettings(true),
		},
	];

	return (
		<div>
			<CommandBar farItems={farItems} items={cItems} />
			<SettingsPanel show={showSettings} onClose={() => setShowSettings(false)} />
		</div>
	);
};
