import { ILogItem } from '../models/LogItem';

export class LocalStorageService {
	private storageKey = 'RbbTtsMonitoringCache';

	public getCache(): ILogItem[] {
		const cache = localStorage.getItem(`${this.storageKey}`);
		if (!cache) return [];
		const items: ILogItem[] = JSON.parse(cache);
		return items;
	}

	public setCache(items: ILogItem[]) {
		const json = JSON.stringify(items);
		localStorage.setItem(`${this.storageKey}`, json);
	}
}
