import React from 'react';
import { TextField } from 'office-ui-fabric-react';

export interface ISourceTextProps {
	text: string;
	onChange: (value: string) => void;
}

export const SourceText = (props: ISourceTextProps): JSX.Element => {
	return (
		<TextField
			rows={20}
			value={props.text}
			multiline
			onChange={(ev, text) => {
				props.onChange(text || '');
			}}
		/>
	);
};
