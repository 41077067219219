import React from 'react';
import { Slider } from 'office-ui-fabric-react';

import styles from './TtsSettings.module.scss';

export interface IVelocityPitchSliderProps {
	velocity: number;
	pitch: number;
	onChangeVelocity: (value: number) => void;
	onChangePitch: (value: number) => void;
}

export const VelocityPitchSlider = (props: IVelocityPitchSliderProps): JSX.Element => {
	return (
		<div className={styles.section}>
			<Slider
				label={`Sprachgeschwindigkeit: ${props.velocity}`}
				max={3}
				min={0}
				showValue={false}
				step={0.01}
				value={props.velocity}
				onChange={props.onChangeVelocity}
			/>
			<Slider
				label={`Tonhöhe: ${props.pitch}`}
				max={2}
				min={0}
				showValue={false}
				step={0.1}
				value={props.pitch}
				onChange={props.onChangePitch}
			/>
		</div>
	);
};
