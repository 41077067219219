import React from 'react';
import { ILogItem } from '../../models/LogItem';
import { ITtsCommandBar, TtsCommandBar } from '../molecules/TtsCommandBar';
import { Header } from '../organisms/Header';
import { Monitoring } from '../organisms/Monitoring';

import styles from './RbbTtsTemplate.module.scss';

export interface IMonitoringTemplate {
	commandbar: ITtsCommandBar;
	logs: ILogItem[];
}

export const MonitoringTemplate = (props: IMonitoringTemplate): JSX.Element => {
	return (
		<div className={styles.main}>
			<Header />
			<TtsCommandBar {...props.commandbar} />
			<Monitoring items={props.logs} />
		</div>
	);
};
