export interface ILogItem {
	startTime: Date;
	endTime: Date;
	duration: string;
	filename: string;
	category: string;
	state: string;
	message: string;
}

export class LogItem {
	public static parse(data: any): ILogItem {
		return {
			startTime: data.startTime,
			endTime: data.endTime,
			duration: data.duration,
			filename: data.filename,
			category: this.getCategory(data.category),
			state: this.getState(data.state),
			message: data.message,
		};
	}

	public static parseAll(data: any): ILogItem[] {
		return data.map((d: any) => this.parse(d));
	}

	private static getCategory(category: number): string {
		switch (category) {
			case 1:
				return 'Wetter';
			case 2:
				return 'Verkehr';
			case 3:
				return 'Gefahrenmeldung';
			case 4:
				return 'Entwarnung';
			case 5:
				return 'Benutzerdefiniert';
			default:
				return 'Unbekannt';
		}
	}

	private static getState(state: number): string {
		switch (state) {
			case 0:
				return 'Gestartet';
			case 1:
				return 'Erfolgreich';
			case 2:
				return 'Fehlerhaft';
			default:
				return 'Unbekannt';
		}
	}
}
