import React, { useMemo, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';

import { RbbTtsTemplate } from '../components/templates/RbbTtsTemplate';
import { Configuration } from '../config/Configuration';
import { ITextToSpeechOptions } from '../models/ITextToSpeechOptions';
import { TextToSpeechService } from '../services/TextToSpeechService';
import { ITtsCommandBar } from '../components/molecules/TtsCommandBar';

export interface ITextToSpeechPage {
	commandbar: ITtsCommandBar;
	ttsOptions: ITextToSpeechOptions;
}

export const TextToSpeechPage = (props: ITextToSpeechPage): JSX.Element => {
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0]);
	const config = useMemo(() => new Configuration(), []);
	const [testing, setTesting] = useState(false);
	const [saved, setSaved] = useState(false);
	const [cancelled, setCancelled] = useState(false);
	const [audioUrl, setAudioUrl] = useState('');
	const [ttsOptions, setTtsOptions] = useState<ITextToSpeechOptions>(props.ttsOptions);

	const getAccessToken = async () => {
		if (!account) return Promise.reject('no authorized user');

		const response = await instance.acquireTokenSilent({
			scopes: [config.scope],
			account,
		});

		if (!response) return Promise.reject('no access token retrieved');

		return response.accessToken;
	};

	const onChangeText = (value: string) => {
		setTtsOptions({ ...ttsOptions, text: value });
	};

	const onChangeCategory = (value: string) => {
		setTtsOptions({ ...ttsOptions, category: value });
	};

	const onChangeVoice = (value: string) => {
		setTtsOptions({ ...ttsOptions, voiceName: value });
	};

	const onChangeVelocity = (value: number) => {
		setTtsOptions({ ...ttsOptions, prosodyRate: value });
	};

	const onChangePitch = (value: number) => {
		setTtsOptions({ ...ttsOptions, prosodyPitch: value });
	};

	const onPlay = async () => {
		if (!ttsOptions) return;
		setTesting(true);
		const accessToken = await getAccessToken();
		const url = await TextToSpeechService.Test(ttsOptions, accessToken);
		setAudioUrl(url);
	};

	const onStop = () => {
		setTesting(false);
		setAudioUrl('');
	};

	const onSave = async () => {
		if (!ttsOptions) return;

		if (ttsOptions.category) {
			setSaved(true);
			setInterval(() => setSaved(false), 2000);
			const accessToken = await getAccessToken();
			await TextToSpeechService.Save(ttsOptions, accessToken);
		} else {
			setCancelled(true);
			setInterval(() => setCancelled(false), 2000);
		}
	};

	return (
		<RbbTtsTemplate
			commandbar={props.commandbar}
			actions={{ testing, audioUrl, saved, cancelled, onPlay, onStop, onSave }}
			settings={{
				category: ttsOptions.category,
				onChangeCategory,
				onChangeVoice,
				voiceName: ttsOptions.voiceName,
			}}
			slider={{ velocity: ttsOptions.prosodyRate, pitch: ttsOptions.prosodyPitch, onChangeVelocity, onChangePitch }}
			source={{ text: ttsOptions.text, onChange: onChangeText }}
		/>
	);
};
