import { ITtsSettings } from '../models/ITtsSettings';
import { xhr } from '../xhr';

export class SettingsService {
	public static async Load(accesstoken: string): Promise<ITtsSettings> {
		try {
			const response = await xhr(`TtsSettings`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accesstoken}`,
				},
			});
			return { enabledSynth: response.data.enabledsynth, enabledAdb: response.data.enabledadb };
		} catch (ex) {
			console.error(`error on loading settings: ${ex}`);
			return { enabledSynth: true, enabledAdb: true };
		}
	}

	public static async Upsert(settings: ITtsSettings, accesstoken: string) {
		try {
			await xhr(`TtsSettings`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${accesstoken}`,
				},
				data: settings,
			});
		} catch (ex) {
			console.error(`error on updating settings: ${ex}`);
		}
	}
}
