import axios, { AxiosRequestConfig } from 'axios';

export class AccountService {
	public static async getAccountPicture(accesstoken: string): Promise<string | undefined> {
		try {
			const options: AxiosRequestConfig = {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${accesstoken}`,
				} as Record<string, string>,
				responseType: 'blob',
			};
			const response = await axios(`https://graph.microsoft.com/v1.0/me/photo/$value`, options);
			const urlCreator = window.URL || window.webkitURL;
			return urlCreator.createObjectURL(response.data);
		} catch (error) {
			return undefined;
		}
	}
}
