import React from 'react';
import { ISourceTextProps } from '../atoms/SourceText';
import { IPlaySaveActionsProps } from '../molecules/PlaySaveActions';
import { ITtsCommandBar, TtsCommandBar } from '../molecules/TtsCommandBar';
import { ITtsSettingsProps } from '../molecules/TtsSettings';
import { IVelocityPitchSliderProps } from '../molecules/VelocityPitchSlider';
import { Header } from '../organisms/Header';
import { TextToSpeech } from '../organisms/TextToSpeech';

import styles from './RbbTtsTemplate.module.scss';

export interface IRbbTtsTemplate {
	commandbar: ITtsCommandBar;
	source: ISourceTextProps;
	settings: ITtsSettingsProps;
	slider: IVelocityPitchSliderProps;
	actions: IPlaySaveActionsProps;
}

export const RbbTtsTemplate = (props: IRbbTtsTemplate): JSX.Element => {
	return (
		<div className={styles.main}>
			<Header />
			<TtsCommandBar {...props.commandbar} />
			<TextToSpeech source={props.source} settings={props.settings} slider={props.slider} actions={props.actions} />
		</div>
	);
};
