import React from 'react';
import moment from 'moment-timezone';
import { DetailsList, IColumn } from 'office-ui-fabric-react';
import { ILogItem } from '../../models/LogItem';

import 'moment/locale/de';

export interface ILogListProps {
	items: ILogItem[];
}

export const LogList = (props: ILogListProps): JSX.Element => {
	moment.locale('de');
	const dateFieldLength = 180;
	const columns: IColumn[] = [
		{
			key: 'startTime',
			name: 'Startzeit',
			fieldName: 'startTime',
			minWidth: dateFieldLength,
			maxWidth: dateFieldLength,
		},
		{ key: 'endTime', name: 'Endzeit', fieldName: 'endTime', minWidth: dateFieldLength, maxWidth: dateFieldLength },
		{ key: 'duration', name: 'Dauer', fieldName: 'duration', minWidth: 60, maxWidth: 60 },
		{ key: 'filename', name: 'Dateiname', fieldName: 'filename', minWidth: 250, maxWidth: 250 },
		{ key: 'category', name: 'Kategorie', fieldName: 'category', minWidth: 150, maxWidth: 150 },
		{ key: 'state', name: 'Status', fieldName: 'state', minWidth: 80, maxWidth: 80 },
		{ key: 'message', name: 'Fehlermeldung', fieldName: 'message', minWidth: 300 },
	];

	const renderItemColumn = (item?: ILogItem, index?: number, column?: IColumn) => {
		if (!item || !column) return;
		switch (column.key) {
			case 'startTime':
				const startTime = item[column.fieldName as keyof ILogItem];
				const startUtc = moment.utc(startTime);
				const startCet = startUtc.tz('Europe/Berlin');
				return <span>{startCet.format('LLLL')}</span>;
			case 'endTime':
				const endTime = item[column.fieldName as keyof ILogItem];
				if (!endTime) return <span></span>;
				const endUtc = moment.utc(endTime);
				const endCet = endUtc.tz('Europe/Berlin');
				return <span>{endCet.format('LLLL')}</span>;
			default:
				const content = item[column.fieldName as keyof ILogItem] as string;
				return <span>{content}</span>;
		}
	};

	return (
		<DetailsList
			items={props.items.sort((a, b) => (a.startTime < b.startTime ? 1 : -1))}
			columns={columns}
			onRenderItemColumn={renderItemColumn}
		/>
	);
};
