import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { Configuration } from './config/Configuration';

export interface IResponse {
	data: any;
	contentType: string;
	headers: any;
}

const config = new Configuration();

export interface IRequestConfig extends AxiosRequestConfig {
	onError?: (response: AxiosError) => Promise<IResponse>;
}

async function xhr(url: string, options?: IRequestConfig): Promise<IResponse> {
	const opts = { method: 'GET', ...options, withCredentials: true } as IRequestConfig;
	opts.headers = {
		'content-type': 'application/json',
		'x-functions-key': config.functionKey,
		...opts?.headers,
	} as Record<string, string>;

	//disable cache for ie
	if ((window.document as any).documentMode && opts.method!.toLocaleLowerCase() === 'get') {
		opts.params = { _r: Math.random().toString() };
	}

	try {
		const apiBasePath = config.api;
		const response = await axios(`${apiBasePath}${url}`, opts);
		const contentType = (response.headers['content-type'] || '').toLowerCase();

		return { data: response.data, contentType, headers: response.headers };
	} catch (e) {
		const ex = e as AxiosError;

		if (opts.onError) {
			return opts.onError(ex);
		}

		return Promise.reject(e);
	}
}

export { xhr };
