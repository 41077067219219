import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { Configuration as AppConfig } from './config/Configuration';

const appConfig = new AppConfig();

// MSAL configuration
const configuration: Configuration = {
	auth: {
		authority: `https://login.microsoftonline.com/${appConfig.tenantId}`,
		clientId: appConfig.clientId,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true,
	},
};

export const pca = new PublicClientApplication(configuration);
