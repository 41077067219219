import { Label } from 'office-ui-fabric-react';
import React from 'react';
import { UserInfo } from '../molecules/UserInfo';

import styles from './Header.module.scss';

export const Header = (): JSX.Element => {
	return (
		<div className={styles.header}>
			<div className={styles.ttsLogo} />
			<Label className={styles.ttsTitle}>Text-To-Speech</Label>
			<UserInfo />
		</div>
	);
};
