import { useAccount, useMsal } from '@azure/msal-react';
import React, { useEffect, useMemo, useState } from 'react';
import { ITtsCommandBar } from '../components/molecules/TtsCommandBar';

import { MonitoringTemplate } from '../components/templates/MonitoringTemplate';
import { Configuration } from '../config/Configuration';
import { ILogItem, LogItem } from '../models/LogItem';
import { LocalStorageService } from '../services/LocalStorageService';
import { MonitoringService } from '../services/MonitoringService';

export interface IMonitoringPage {
	commandbar: ITtsCommandBar;
}

export const MonitoringPage = (props: IMonitoringPage): JSX.Element => {
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0]);
	const config = useMemo(() => new Configuration(), []);
	const storageSrv = new LocalStorageService();
	const [logs, setLogs] = useState<ILogItem[]>(storageSrv.getCache());

	const getAccessToken = async () => {
		if (!account) return Promise.reject('no authorized user');

		const response = await instance.acquireTokenSilent({
			scopes: [config.scope],
			account,
		});

		if (!response) return Promise.reject('no access token retrieved');

		return response.accessToken;
	};

	useEffect(() => {
		const loadLogs = async () => {
			const accessToken = await getAccessToken();
			const result = await MonitoringService.GetCurrentMonthLogs(accessToken);
			const logItems = LogItem.parseAll(result);
			setLogs(logItems);
			storageSrv.setCache(logItems);
		};
		loadLogs();
	}, [account]);

	return <MonitoringTemplate commandbar={props.commandbar} logs={logs} />;
};
