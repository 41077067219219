import React from 'react';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';

import styles from './TtsSettings.module.scss';

export interface ITtsSettingsProps {
	category: string;
	voiceName: string;
	onChangeCategory: (value: string) => void;
	onChangeVoice: (value: string) => void;
}

export const TtsSettings = (props: ITtsSettingsProps): JSX.Element => {
	const categoryOptions: IDropdownOption[] = [
		{ key: 'weather', text: 'Wetter' },
		{ key: 'traffic', text: 'Verkehr' },
		{ key: 'warning', text: 'Gefahrenmeldung', disabled: true },
		{ key: 'custom', text: 'Benutzerdefiniert' },
	];

	const voiceOptions: IDropdownOption[] = [
		{ key: 'de-DE-KatjaNeural', text: 'Katja' },
		{ key: 'de-DE-ConradNeural', text: 'Conrad' },
		{ key: 'de-DE-HeddaRUS', text: 'Hedda' },
		{ key: 'de-DE-Stefan', text: 'Stefan' },
		{ key: 'LillyNeural', text: 'Lilly' },
	];

	return (
		<div className={styles.section}>
			<Dropdown
				defaultSelectedKey={categoryOptions.find((co) => co.text === props.category)?.key}
				label='Kategorie'
				options={categoryOptions}
				onChange={(ev, option) => {
					props.onChangeCategory(option?.text.toString() || '');
				}}
			/>

			<Dropdown
				defaultSelectedKey={props.voiceName}
				label='Stimme'
				options={voiceOptions}
				onChange={(ev, option) => {
					props.onChangeVoice(option?.key.toString() || '');
				}}
			/>
		</div>
	);
};
