import { ISsmlOptions } from '../models/ISsmlOptions';
import { ITextToSpeechOptions } from '../models/ITextToSpeechOptions';
import { xhr } from '../xhr';

export class TextToSpeechService {
	public static async AuthTest(accessToken: string) {
		try {
			const response = await xhr(`AuthTest`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return response.data;
		} finally {
			//
		}
	}

	public static async Test(ssmlOptions: ISsmlOptions, accessToken: string) {
		try {
			const response = await xhr(`TestTextToSpeech`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				data: ssmlOptions,
			});
			return response.data;
		} finally {
			//
		}
	}

	public static async Save(textToSpeechOptions: ITextToSpeechOptions, accessToken: string) {
		try {
			await xhr(`SaveTextToSpeech`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				data: textToSpeechOptions,
			});
		} finally {
			//
		}
	}
}
