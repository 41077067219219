import React, { useEffect } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';

import './App.scss';
import AuthenticatedApp from './AuthenticatedApp';

const App = () => {
	const { result, error } = useMsalAuthentication(InteractionType.Redirect);

	useEffect(() => {
		if (result) console.debug(`MSAL result: ${JSON.stringify(result)}`);
	}, [result]);

	if (error) console.error(`Error on authorization: ${error}`);

	return (
		<div>
			<React.Fragment>
				<AuthenticatedTemplate>
					<AuthenticatedApp />
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<p>No users are signed in!</p>
				</UnauthenticatedTemplate>
			</React.Fragment>
		</div>
	);
};

export default App;
