/*[object Object]*/
import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
import { initializeIcons } from '@uifabric/icons';
import { MsalProvider } from '@azure/msal-react';
import { pca } from './MsalProvider';
import { createTheme, loadTheme } from 'office-ui-fabric-react';

initializeIcons();

const rbbTheme = createTheme({
	palette: {
		themePrimary: '#e31818',
		themeLighterAlt: '#fef5f5',
		themeLighter: '#fbd7d7',
		themeLight: '#f7b5b5',
		themeTertiary: '#ee6f6f',
		themeSecondary: '#e63232',
		themeDarkAlt: '#cc1616',
		themeDark: '#ac1313',
		themeDarker: '#7f0e0e',
		neutralLighterAlt: '#faf9f8',
		neutralLighter: '#f3f2f1',
		neutralLight: '#edebe9',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#c6c6c6',
		neutralSecondary: '#8c8c8c',
		neutralPrimaryAlt: '#575757',
		neutralPrimary: '#414141',
		neutralDark: '#303030',
		black: '#242424',
		white: '#ffffff',
	},
});

loadTheme(rbbTheme);

ReactDOM.render(
	<React.StrictMode>
		<MsalProvider instance={pca}>
			<App />
		</MsalProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
