import { IConfigModel } from '../models/IConfigModel';
import { xhr } from '../xhr';

export class ConfigService {
	public static async Load(accesstoken: string): Promise<IConfigModel> {
		try {
			const response = await xhr(`TtsConfig`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accesstoken}`,
				},
			});
			return {
				category: response.data.defaultCategory,
				targetFilename: '',
				voiceName: response.data.defaultVoice,
				prosodyRate: response.data.defaultProsodyRate,
				prosodyPitch: response.data.defaultProsodyPitch,
				text: response.data.defaultText,
				speechStudioUrl: response.data.speechStudioUrl,
			};
		} catch (ex) {
			console.error(`error on loading config: ${ex}`);
			return {
				category: 'Wetter',
				targetFilename: '',
				voiceName: 'de-DE-ConradNeural',
				prosodyRate: 1.05,
				prosodyPitch: 1,
				text:
					'Sie können diesen Text durch jeden beliebigen Text ersetzen. Sie können entweder in dieses Textfeld schreiben oder Ihren eigenen Text hier einfügen.',
				speechStudioUrl: '/',
			};
		}
	}
}
