import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TextToSpeechPage } from './pages/TextToSpeechPage';
import { MonitoringPage } from './pages/MonitoringPage';
import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect, useMemo, useState } from 'react';
import { Configuration } from './config/Configuration';
import { ITextToSpeechOptions } from './models/ITextToSpeechOptions';
import { IConfigModel } from './models/IConfigModel';
import { ConfigService } from './services/ConfigService';

const AuthenticatedApp = () => {
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0]);
	const config = useMemo(() => new Configuration(), []);
	const [speechStudioUrl, setSpeechStudioUrl] = useState('/');
	const [ttsOptions, setTtsOptions] = useState<ITextToSpeechOptions>({
		category: 'Wetter',
		targetFilename: '',
		voiceName: 'de-DE-ConradNeural',
		prosodyRate: 1.05,
		prosodyPitch: 1,
		text:
			'Sie können diesen Text durch jeden beliebigen Text ersetzen. Sie können entweder in dieses Textfeld schreiben oder Ihren eigenen Text hier einfügen.',
	});

	useEffect(() => {
		const loadConfig = async (): Promise<IConfigModel> => {
			const token = await getAccessToken();
			const config = await ConfigService.Load(token);
			return config;
		};
		loadConfig().then((config) => {
			setTtsOptions(config);
			setSpeechStudioUrl(config.speechStudioUrl);
		});
	}, [account]);

	const getAccessToken = async () => {
		if (!account) return Promise.reject('no authorized user');

		const response = await instance.acquireTokenSilent({
			scopes: [config.scope],
			account,
		});

		if (!response) return Promise.reject('no access token retrieved');

		return response.accessToken;
	};

	return (
		<Router>
			<Switch>
				<Route path='/monitoring'>
					<MonitoringPage commandbar={{ speechStudioUrl: speechStudioUrl }} />
				</Route>
				<Route path='/'>
					<TextToSpeechPage commandbar={{ speechStudioUrl: speechStudioUrl }} ttsOptions={ttsOptions} />
				</Route>
			</Switch>
		</Router>
	);
};

export default AuthenticatedApp;
