import { Panel, Toggle } from 'office-ui-fabric-react';
import { useSettings } from '../../hooks/useSettings';

export interface ISettingsPanelProps {
	show: boolean;
	onClose: () => void;
}

export const SettingsPanel = (props: ISettingsPanelProps): JSX.Element => {
	const [settings, setSettings] = useSettings();

	return (
		<Panel
			isLightDismiss
			headerText='Einstellungen'
			isOpen={props.show}
			closeButtonAriaLabel='Schließen'
			onDismiss={props.onClose}
		>
			<Toggle
				checked={settings.enabledSynth}
				label='Text-Synthetisierung'
				onText='Aktiviert'
				offText='Deaktiviert'
				onChange={(ev, checked) => setSettings({ enabledSynth: checked ?? false, enabledAdb: settings.enabledAdb })}
			></Toggle>
			<Toggle
				checked={settings.enabledAdb}
				label='Adb-Synchronisierung'
				onText='Aktiviert'
				offText='Deaktiviert'
				onChange={(ev, checked) => setSettings({ enabledSynth: settings.enabledSynth, enabledAdb: checked ?? false })}
			></Toggle>
		</Panel>
	);
};
