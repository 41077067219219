import { xhr } from '../xhr';

export class MonitoringService {
	public static async GetCurrentMonthLogs(accessToken: string) {
		try {
			const response = await xhr(`LoadCurrentMonthLogs`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			return response.data;
		} finally {
			//
		}
	}
}
