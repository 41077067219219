import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

import styles from './PlaySaveActions.module.scss';

export interface IPlaySaveActionsProps {
	testing: boolean;
	saved: boolean;
	cancelled: boolean;
	audioUrl: string;
	onPlay: () => void;
	onStop: () => void;
	onSave: () => void;
}

export const PlaySaveActions = (props: IPlaySaveActionsProps): JSX.Element => {
	return (
		<div className={styles.ttsActions}>
			{props.testing ? (
				<PrimaryButton iconProps={{ iconName: 'Stop' }} onClick={props.onStop}>
					Stopp
				</PrimaryButton>
			) : (
				<PrimaryButton iconProps={{ iconName: 'Play' }} onClick={props.onPlay}>
					Test
				</PrimaryButton>
			)}
			{props.saved ? (
				<DefaultButton iconProps={{ iconName: 'CheckMark' }} disabled>
					Gespeichert
				</DefaultButton>
			) : props.cancelled ? (
				<DefaultButton iconProps={{ iconName: 'Cancel' }} disabled>
					Abgebrochen
				</DefaultButton>
			) : (
				<DefaultButton iconProps={{ iconName: 'Save' }} onClick={props.onSave}>
					Speichern
				</DefaultButton>
			)}
		</div>
	);
};
